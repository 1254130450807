@import './utils/variables';

.home-component {
  position: relative;

  .app-content {
    position: absolute;
    left: $navSize;
    overflow-y: auto;
    width: calc(100vw - #{$navSize});
    overflow-x: hidden;
  } 
}

.home-component, .home-component .app-content {
  height: calc(100vh - #{$navSize});
}


