// Theme colors
$themes: (
  light: (
    primary-bg: #ffffff,
    secondary-bg: #f5f5f5,
    primary-text: #282828,
    secondary-text: #666666,
    accent: #f0f56c,
    accent-hover: lighten(#f0f56c, 10%),
    border: rgba(0, 0, 0, 0.1),
    card-bg: #ffffff,
    modal-bg: rgba(255, 255, 255, 0.95),
    modal-overlay: rgba(0, 0, 0, 0.5),
    error: #ff4d4d,
    success: #4caf50,
    warning: #ffb703,
    input-bg: #f5f5f5,
    shadow: rgba(0, 0, 0, 0.1),
    blur-effect: rgba(240, 245, 108, 0.15),
  ),
  dark: (
    primary-bg: #000000,
    secondary-bg: #282828,
    primary-text: #ffffff,
    secondary-text: rgba(255, 255, 255, 0.7),
    accent: #f0f56c,
    accent-hover: lighten(#f0f56c, 10%),
    border: rgba(255, 255, 255, 0.3),
    card-bg: #2a2a2a,
    modal-bg: rgba(40, 40, 40, 0.95),
    modal-overlay: rgba(0, 0, 0, 0.7),
    error: #ff4d4d,
    success: #4caf50,
    warning: #ffb703,
    input-bg: rgba(40, 40, 40, 0.5),
    shadow: rgba(0, 0, 0, 0.2),
    blur-effect: rgba(240, 245, 108, 0.15),
  ),
);

// Function to get theme value
@function theme-value($theme, $key) {
  @return map-get(map-get($themes, $theme), $key);
}

// Mixin for theme-specific styles
@mixin themed() {
  @each $theme, $map in $themes {
    .theme-#{$theme} & {
      $theme-map: () !global;
      @each $key, $value in $map {
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

// Common variables
$font-family-primary: "Poppins", sans-serif;
$border-radius-sm: 5px;
$border-radius-md: 10px;
$border-radius-lg: 21.292px;
$spacing-unit: 8px;

// Existing variables (kept for backward compatibility)
$primaryColor: #282828;
$secondaryColor: #ffb703;
$navSize: 0px;
