.react-tel-input {
	font-size: 40px !important;
}

.react-tel-input .form-control {
	margin-top: 0 !important;
	margin-bottom: 0 !important;
    height: 2.5rem !important;
	position: relative;
	letter-spacing: .01rem;
	border-radius: 30px !important;
	margin-top: 20 !important;
	margin-bottom: 20 !important;
	width: 100% !important;
	font-size: 0.9rem !important;
}