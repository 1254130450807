@import '../../utils/variables';

.doc-title{
    color: $primaryColor;
    font-size: 1.5rem;
    text-align: left;
}

p{
    color: $primaryColor;
    font-size: medium;
    font-weight: 500;
    text-align: justify;
}

.subtitle{
    color: $primaryColor;
    font-size: 1.2rem;
    font-weight: 600;
    text-align: center;
    text-decoration: underline;
    margin-bottom: 20px;
    margin-top: 20px;
}

ol{
    margin-bottom: 20px;
}

u{
    font-weight: 600;
}