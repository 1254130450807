.business-auth-background{
    background-image: url('../Assets/img/backgrounds/businessBackground.png');
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    
}

.signup-mantis-logo{
   width: 300px;
   text-align: left;
    
}

.business-signup-form-container{
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding-left: 50px;
    padding-right: 50px;
}

 .business-signup-title{
    font-size: 2.0rem;
    font-weight: 500;
    margin-top: 20px;
    text-align: left;
   font-family: "Poppins", sans-serif;
 }   

 .business-signup-input{
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    background-color: #f5f5f5;
    border-radius: 10px;
    width: 100%;
    height: 50px;
    margin-top: 20px;
      font-family: "Poppins", sans-serif;
 }

 .business-signup-button{
    width: 200px;
    height:40px;
    background-color: #282828;
    color: white;
    font-size: 16px;
    font-weight: 600;
    border-radius: 10px;
    text-align: center;
    cursor: pointer;
    margin-top: 20px;
    border-color: #282828;
    &:hover{
        background-color: #000000;
        border-color: #000000;
    }
 }

 .business-signup-login-link{
      text-align: center;
      margin-top: 20px;
      font-size: 16px;
      font-weight: 600;
      color: #282828;
      cursor: pointer;
      text-decoration: underline;
      font-family: "Poppins", sans-serif;
      &:hover{
         color: #000000;
      }
 }