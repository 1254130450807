@import '../../utils/variables';

.home-component, .home-component .app-content {
  height: calc(100vh - #{$navSize});
  text-align: center;
  overflow-x: hidden;
}

.sidebar-menu-component {

  nav {
    background-color: $primaryColor;
    margin-top: 50px;
    height: calc(100vh - #{$navSize});
  }

  .separator {
    clear: both;
    position: relative;
    margin: .8rem 0;
    background-color: #DDD;
    height: 1px;
  }
}