
    .FilesDragAndDrop{
      position: relative;
      .FilesDragAndDrop__area {
        width: 100%;
        height: 200px;
        padding: 50px;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        flex-flow: column nowrap;
        font-size: 16px;
        color: #555555;
        border: 2px #c3c3c3 dashed;
        border-radius: 12px;
        font-family: "Poppins", sans-serif;
        .area__icon {
          font-size: 50px;
        }
      }
      .FilesDragAndDrop__placeholder {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 9999;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column nowrap;
        background-color: #e7e7e7;
        border-radius: 12px;
        color: #7f8e99;
        font-size: 24px;
        font-family: "Poppins", sans-serif;
        opacity: .9;
        text-align: center;
        line-height: 1.4;
        &.FilesDragAndDrop__placeholder--error {
          background-color: #f7e7e7;
          color: #cf8e99;
        }
      
        &.FilesDragAndDrop__placeholder--success {
          background-color: #e7f7e7;
          color: #8ecf99;
        }
      }
    }
  