.login-mantis-logo{
    width: 400px;
}

.login-label{
    color:#023047;
    font-weight: 600;
}

.login-button{
    margin-top: 20px;
    background-color: #023047;
    border-color: #023047;
}

.login-input-row{
    width: 20%;
    margin: auto;
    margin-top: 20px;
}
.login-input-row-mobile{
    width: 50%;
    margin: auto;
    margin-top: 20px;
}

.reset-button{
    margin-top: 20px;
    background-color: #282828;
    border-color: #282828;
}